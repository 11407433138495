<template>
  <Layout>
    <div class="page-header">
      <h1>Bank Accounts </h1>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th> Your identifier </th>
          <th> Bank module </th>
          <th> Bank login </th>
          <th> Bank secret </th>
          <th> Status </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(useraccount, id, index) in useraccounts" :key="index">
          <td
            ><b-icon-x-square
              variant="danger"
              @click="removeAccount(id)"
            ></b-icon-x-square
          ></td>
          <td>{{ id }}</td>
          <td
            ><ClickToSelect
              :value="useraccount.bank"
              :options="bankList"
              @input="onUpdate(id, 'bank', $event)"
          /></td>
          <td
            ><ClickToEdit
              :value="useraccount.login"
              @input="onUpdate(id, 'login', $event)"
          /></td>
          <td
            ><ClickToEdit
              :value="useraccount.secret"
              type="secret"
              @input="onUpdate(id, 'secret', $event)"
          /></td>
          <td>
            last fetch was {{ useraccount.last_fetch_status }} as of
            {{ useraccount.last_fetch_updated }}
          </td>
        </tr>
      </tbody>
    </table>

    <button @click="addUserAccountsModal = !addUserAccountsModal"
      >Add new</button
    >

    <b-modal
      v-model="addUserAccountsModal"
      title="Add new bank user account"
      @ok="submitNewUserAccount"
    >
      Choose a custom name for your account:
      <b-form-input v-model="addUserAccountsName"></b-form-input>
      Select bank:
      <b-form-select
        v-model="addUserAccountsBank"
        :options="bankList"
      ></b-form-select>
      Login:
      <b-form-input v-model="addUserAccountsLogin"></b-form-input>
      Password:
      <b-form-input v-model="addUserAccountsSecret"></b-form-input>
      (Optional) Extra fields:
      <ul>
        <li v-for="(value, key) in addUserAccountsExtras" :key="key"
          >{{ key }}: {{ value }} <span @click="delExtra(key)">X</span></li
        >
      </ul>
      Add a new field: Name (example "birthday"):
      <b-form-input v-model="addUserAccountsExtraName"></b-form-input>
      Value:
      <b-form-input v-model="addUserAccountsExtraValue"></b-form-input>
      <span @click="addExtra">ADD</span>
    </b-modal>
  </Layout>
</template>

<script>
import Layout from '@layouts/main.vue'
import ClickToEdit from '@components/_base-click-to-edit.vue'
import ClickToSelect from '@components/_base-click-to-select.vue'

export default {
  page: {
    title: 'Bank Accounts',
    meta: [{ name: 'description', content: 'Bank Accounts.' }],
  },
  components: { Layout, ClickToEdit, ClickToSelect },
  data: function() {
    return {
      addUserAccountsModal: false,
      addUserAccountsName: '',
      addUserAccountsBank: '',
      addUserAccountsLogin: '',
      addUserAccountsSecret: '',
      addUserAccountsExtras: {},
      addUserAccountsExtraName: '',
      addUserAccountsExtraValue: '',
      bankList: ['ing', 'bnporc', 'boursorama'],
    }
  },
  computed: {
    useraccounts() {
      return this.$store.getters['api/get']('useraccounts')
    },
  },
  created() {
    this.$store.dispatch('api/get', 'useraccounts')
  },
  methods: {
    addExtra() {
      this.addUserAccountsExtras[
        this.addUserAccountsExtraName
      ] = this.addUserAccountsExtraValue
      this.addUserAccountsExtraName = this.addUserAccountsExtraValue = ''
    },
    delExtra(key) {
      this.$delete(this.addUserAccountsExtras, key)
    },
    submitNewUserAccount() {
      this.$store.dispatch('api/post', {
        login: this.addUserAccountsLogin,
        bank: this.addUserAccountsBank,
        secret: this.addUserAccountsSecret,
        extras: this.addUserAccountsExtras,
        _jv: {
          id: this.addUserAccountsName,
          type: 'useraccounts',
        },
      })
      // .catch((errs) => {
      //   if (errs.response) {
      //     // API error
      //     console.log('HTTP Error Code:', errs.response.status)
      //     // Work with each error from the JSONAPI 'errors' array
      //     for (const err of errs.response.data.errors) {
      //       console.log(err.detail)
      //     }
      //   } else {
      //     // Some other type of error
      //     console.log('Other error:', errs.message)
      //   }
      // })
      this.addUserAccountsName = this.addUserAccountsBank = this.addUserAccountsLogin = this.addUserAccountsSecret = this.addUserAccountsExtraName = this.addUserAccountsExtraValue =
        ''
      this.addUserAccountsExtras = []
      this.addUserAccountsModal = false
    },
    onUpdate: function(accountId, attribute, value) {
      // TODO: add a progress bar
      this.$store
        .dispatch('api/patch', {
          [attribute]: value,
          _jv: {
            id: accountId,
            type: 'useraccounts',
          },
        })
        .then((res) => {
          // TODO: remove the progress bar
          // console.log('Update OK')
        })
        .catch((errs) => {
          // TODO: do something with the errors
          // if (errs.response) {
          //   console.log('HTTP error code:', errs.response.status)
          //   for (const err of errs.response.data.errors) {
          //     console.log(err.detail)
          //   }
          // } else {
          //   console.log(errs.message)
          // }
        })
    },
    removeAccount: function(id) {
      this.$store.dispatch('api/delete', {
        _jv: { type: 'useraccounts', id: id },
      })
    },
  },
}
</script>
